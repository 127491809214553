<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">Arty System</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">Inicializar Base de Datos</p>
          <p class="mb-2 text-center">Debe subir el archivo para incializar el programa</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              :prepend-icon="icons.mdiDomain"
              v-model="nombreEmpresa"
              outlined
              label="Empresa"
              placeholder="JohnDoe"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              :prepend-icon="icons.mdiFormTextboxPassword"
              v-model="codigo"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Codigo"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

          
           
            <v-btn
              @click="Inicializar()"
              :loading="inicializando"
              block
              color="primary"
              class="mt-6" 
            >
              Inicializar DB
            </v-btn>
           
          </v-form>
        </v-card-text>

        <!-- create new account  -->

        <!-- divider -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
    <v-snackbar v-model="isSnackbarVisible" multi-line :light="$vuetify.theme.dark">
      {{ mensaje }}

      <template #action="{ attrs }">
        <v-btn color="error" text v-bind="attrs" @click="isSnackbarVisible = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiBulldozer,
  mdiPaperclip,
  mdiFormTextboxPassword,
  mdiDomain,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import ExportImportServices from '@/api/servicios/ExportImportServices'
import router from '@/router'
export default {
  setup() {
    const isPasswordVisible = ref(false)
    const nombreEmpresa = ref('')
    const codigo = ref('')
    const archivo = ref(null)
    const archivoData = ref(null)
    const isSnackbarVisible = ref(false)
    const mensaje = ref('')
    const reglas = {
      archivo: [value => !value || extraerExtencion(value.name) == 'inic' || 'No es una extencion valia'],
    }
    const inicializando = ref(false)

    const Inicializar = () => {
      inicializando.value = true

      
      ExportImportServices.inicializarDB()
        .then(response => {
          console.log(response.data)
          if (response.data.estatus == true) {
            router.push('/pages/login')
          } else {
            isSnackbarVisible.value = true
            mensaje.value = response.data.mensaje
            console.log(response.data)
          }
          //imagen = response.data.img
        })
        .catch(error => {
          isSnackbarVisible.value = true
          mensaje.value = 'error catch ' + error
        })
        .finally(() => {
          inicializando.value = false
        })

      //dataPrevImg.value = [];
    }

    const ChangeImg = async e => {
      let reader = new FileReader()
      reader.readAsText(e)

      reader.onload = function () {
        archivoData.value = JSON.parse(window.atob(reader.result.split(/\r\n|\r|\n/, -1)[0]).replaceAll('--##', ''))
      }

      reader.onerror = function () {
        console.log(reader.error)
      }
    }
    const extraerExtencion = nombre => {
      let string = [...nombre].reverse().join('').split('.')[0].split('').reverse().join('')

      console.log(string)
      return string
    }
    watch(archivo, () => {
      console.log(archivo.value)
    })
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      nombreEmpresa,
      codigo,
      socialLink,
      reglas,
      archivo,
      ChangeImg,
      archivoData,
      Inicializar,
      inicializando,
      isSnackbarVisible,
      mensaje,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiPaperclip,
        mdiFormTextboxPassword,
        mdiDomain,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
